<template>
  <div>
    <div
      class="inputo p-1 flex items-center pl-4"
      :class="{'error': error !== null,
               'success': success === true}"
      :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
    >
      <div
        class="text-c20 mr-2 cursor-pointer"
        @click="activeOption =! activeOption"
      >
        {{ map }}
      </div>

      <div
        class="w-1/6 flex items-center"
        @click="activeOption =! activeOption"
      >
        <div class="w-4/5 pr-2 cursor-pointer">
          ({{ indicatif }})
        </div>
        <icon
          :data="icons.down"
          height="15"
          width="15"
          class="mr-3 cursor-pointer"
          original
        />
      </div>

      <input
        v-model="value"
        type="text"
        class="w-5/6 h-full pr-3 pl-3 inp"
        :placeholder="placeholder"
        :maxlength="max"
        @keypress="isNumberOnly"
      >
      {{ sendValue }}
    </div>
    <selectListe
      v-if="activeOption"
      :option="options"
      :style="{'width': width}"
      @info="retourSelect"
    />
  </div>
</template>

<script>
import down from '../../../assets/icons/down.svg'
import selectListe from './selectFiltre'
import pays from './pays.json'

export default {
  name: 'Index',
  components: {
    selectListe
  },

  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '40px'
    },
    width: {
      type: String,
      default: '20%'
    },
    border: {
      type: String,
      default: '1px solid rgba(93, 56, 224, 0.3)'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    },
    valueT: {
      type: String,
      default: null
    },
    max: {
      type: Number,
      default: 11
    }
  },

  data () {
    return {
      icons: {
        down
      },
      error: null,
      success: false,
      value: '',
      indicatif: '+229',
      activeOption: false,
      options: [],
      map: '🇧🇯'
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.value.length === 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.success = true
        const phone = this.value.substr(0, 2) + ' ' + this.value.substr(2, 2) + ' ' + this.value.substr(4, 2) + ' ' + this.value.substr(6, 2)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = phone
        this.$emit('info', this.indicatif.toString() + this.value.replace(/ /g, ''))
      }
      if (this.value.length === 10) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = 'email'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = this.value.replace(/ /g, '')
      }
      if (this.value < 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = 'email'
      }
    }
  },

  created () {
    if (this.valueT !== null) {
      this.value = this.valueT.substr(3, 8)
    }
    let tab = []
    for (let item in pays) {
      tab.push(pays[item].map + ' ' + pays[item].name)
    }
    this.options = tab
  },

  methods: {
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },

    emailValidation (email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },

    retourSelect (answer) {
      for (let item in pays) {
        if (pays[item].map + ' ' + pays[item].name === answer){
          this.indicatif = pays[item].callingCodes
          this.map = pays[item].map
        }
      }
      this.activeOption = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.inputo{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
  font-family: $font-default;
  font-size: 14px;
  line-height: 34px;
  color: #0e0e0e;
}
.svg-icon{
  fill: transparent;
}
input{
  height: 20px;
  font-size: 14px;
  line-height: 34px;
  color: #0e0e0e;
  border-radius: 0px 20px 20px 0px;
}
.inputo:focus-within {
  border: 1px solid $focus-color;
}
.inputo:hover {
  border: 1px solid $hover-color;
}
.error{
  border: 1px solid #ea9f9f!important;
}
.success{
  border: 1px solid #5fc187!important;
}
.lab{
  background: #F2F2F2;
  border-radius: 10px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
.inp{
  border-left: 1px solid #C6C6CA;
}
</style>

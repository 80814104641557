<template>
  <div class="w-full">
    <!--    <div class="compressor">-->
    <!--      <imageCompressor-->
    <!--        ref="compressor"-->
    <!--        :done="getFiles"-->
    <!--        :scale="scale"-->
    <!--        :quality="quality"-->
    <!--      />-->
    <!--    </div>-->
    <div
      class="model flex items-center w-full cursor-pointer"
      :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
      @click="openUpload()"
    >
      <div class="w-full h-full flex items-center justify-center">
        <icon
          v-if="url === null && !icon"
          :data="icons.img"
          height="30"
          width="30"
          class="cursor-pointer"
          original
        />

        <icon
          v-if="url === null && icon"
          :data="icon"
          :height="iconHeight"
          :width="iconHeight"
          class="cursor-pointer"
          original
        />

        <img
          v-if="url !== null"
          :src="url"
          alt=""
          class="w-full h-full"
          :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
        >
      </div>
    </div>

    <div
      v-if="placeholder !== 'Cliquez ici pour ajouter l’image'"
      class="mt-3 text-center resp"
    >
      <!--      {{ placeholder }}-->
    </div>
  </div>
</template>

<script>
import img from '../../../assets/icons/ic-upload.svg'

export default {
  name: 'Index',
  components: {
  },

  props: {
    value: {
      type: String,
      default: null
    },
    icon: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '96px'
    },
    border: {
      type: String,
      default: '1px solid #DDDDDD'
    },
    radius: {
      type: String,
      default: '5px'
    },
    background: {
      type: String,
      default: '#F4F4F4'
    },
    iconHeight: {
      type: String,
      default: '40'
    },
  },

  data () {
    return {
      icons: {
        img
      },
      activeDetail: false,
      selectedItem: null,
      file: null,
      url: null,
      scale: 100,
      quality: 30,
      originalSize: true,
      compressed: null,
      placeholder: null
    }
  },

  computed: {

  },

  mounted () {
    if (this.value !== null) {
      this.placeholder = this.value
    }
  },

  methods: {
    openUpload () {
      // const compressor = this.$refs.compressor.$el
      // compressor.click()
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        this.url = URL.createObjectURL(this.file)
        this.placeholder = e.target.files[0].name
        this.$emit('info', this.file)
      }
      input.click()
    },

    getFiles (obj) {
      // console.log(obj)
      this.img = obj.compressed.blob
      this.compressed = obj.compressed

      if (obj.compressed.size !== '0 kB') {
        // console.log('ok')
        const tab = []
        tab.push(obj.compressed.file)
        this.file = tab
        this.placeholder = tab[0].name
        // console.log(this.file)
        this.$emit('info', this.file)
      } else {
        this.quality = Math.floor(Math.random() * 40)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.model {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #D7E0EA!important;
}
.compressor{
  display: none;
}

img{
  object-fit: cover;
  border-radius: 6px;
}
.place{
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
.resp{
  display: none;
}
.button{
  background-color: #EFEFEF;
  height: 30px;
  border-radius: 3px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #5138EE;
}
.model:focus-within {
  border: 1px solid $focus-color;
}
.model:hover {
  border: 1px solid $hover-color;
}
@media only screen and (max-width: 700px) {
 .place{
   display: none;
 }
  .model{
    justify-content: center;
  }
  .resp{
    display: block;
    font-size: 14px;
  }
}
</style>
